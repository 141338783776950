<template>
  <div class="container">
    <div class="banner">
      <swiper ref="swiper" />
    </div>
    <div class="roomDetail">
      <div class="title">{{ info.fx_name }}</div>
      <div class="type">
        <span>{{ info.acreage_min }}-{{ info.acreage_max }}㎡</span>
        <span>|</span>
        <span>{{ info.rental_type == 1 ? "整租" : "合租" }}</span>
      </div>
      <div class="price">
        <span class="num">{{ info.price_min }}-{{ info.price_max }}</span>
        <span class="unit">元/月</span>
      </div>
      <div class="tag_list">
        <div
          class="tag_list_item"
          v-for="(item, index) in info.appliances"
          :key="index"
        >
          {{ item.bq_name }}
        </div>
      </div>
      <div class="btns phone">
        <img src="../../../assets/image/dh.png" />
        <span>
          电话预约：4001883001
          <!-- {{ info.booking_advice_info?.booking_line }} -->
        </span>
      </div>
      <div class="btns look_house" @click="showCode = true">
        <span>预约看房</span>
      </div>
      <div class="nav_title" v-if="info.brief_info">房源描述</div>
      <p class="describe" v-if="info.brief_info">{{ info.brief_info }}</p>
      <div
        v-if="
          info.config &&
          info.config.other_expenses &&
          info.config.other_expenses.length
        "
      >
        <div class="nav_title">费用信息</div>
        <div class="cost">
          <div v-for="(item, index) in info.config.other_expenses" :key="index">
            {{ item.name }}：{{ item.value }}
            <span v-if="item.name == '管理费'">元</span>
            <span v-else-if="item.name == '水费'">元/吨</span>
            <span v-else-if="item.name == '电费'">元/度</span>
            <span v-else-if="item.name == '网络费'">元/月</span>
          </div>
        </div>
      </div>
    </div>
    <div class="configuration">
      <div class="nav_title">房型配置</div>
      <div class="icon_list">
        <div
          class="icon_list_item"
          v-for="(item, index) in filesList"
          :key="index"
        >
          <img :src="item.value" />
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
    <qrcode ref="qrcode" :showCode="showCode" />
    <mobile-Loading v-if="isLoading" />
  </div>
</template>
<script>
import qrcode from "../components/qrcode";
import { getHouseDetail } from "@/api/lookHouse";
import swiper from "../components/swiper/index2.vue";
export default {
  components: {
    qrcode,
    swiper,
  },
  data() {
    return {
      iconList: [
        { icon: "chuang", name: "床" },
        { icon: "rsq", name: "热水器" },
        { icon: "bx", name: "冰箱" },
        { icon: "xyj", name: "洗衣机" },
        { icon: "yyj", name: "油烟机" },
        { icon: "sf", name: "沙发" },
        { icon: "kt", name: "空调" },
        { icon: "yz", name: "餐椅" },
        { icon: "xg", name: "鞋柜" },
        { icon: "czg", name: "餐桌" },
        { icon: "yg", name: "衣柜" },
        { icon: "wbl", name: "微波炉" },
      ],
      filesList: [],
      info: {},
      showCode: false,
      isLoading: true,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        const { data: res } = await getHouseDetail(this.$route.query.id);
        this.info = res;
        //轮播图
        this.$refs.swiper.bannerList = res.files;
        this.$refs.swiper.onChangeSwiper();

        this.$refs.qrcode.path = res?.booking_advice_info?.qrcode.src;
        let iconList = [];
        // this.iconList.forEach((item) => {
        //   res.furniture.forEach((item2) => {
        //     if (item.name == item2) {
        //       iconList.push(item.bq_name);
        //     }
        //   });
        // });
        res.furniture.forEach((item) => {
          iconList.push({
            label: item.bq_name,
            value: item.cover_host + item.cover_path,
          });
        });
        this.filesList = iconList;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.nav_title {
  font-size: 32px;
  color: #333;
  font-weight: bold;
  margin-bottom: 36px;
  margin-top: 50px;
}
.container {
  .banner {
    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }
  .roomDetail {
    padding: 24px 30px 30px 30px;
    box-sizing: border-box;
    color: #666666;
    font-size: 28px;
    .title {
      color: #333;
      font-size: 36px;
      font-weight: bold;
    }
    .type {
      display: flex;
      font-size: 22px;
      color: #999;
      column-gap: 20px;
      align-items: center;
      margin: 30px 0;
    }
    .price {
      .num {
        color: #ff4d4d;
        font-size: 36px;
        font-weight: bold;
      }
      .unit {
        font-size: 24px;
      }
    }
    .tag_list {
      display: flex;
      column-gap: 16px;
      row-gap: 16px;
      flex-wrap: wrap;
      margin-top: 30px;
      &_item {
        font-size: 22px;
        color: #999999;
        padding: 6px 8px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background: #f4f4f4;
        flex-shrink: 0;
      }
    }
    .phone {
      background: #999999;
      margin-top: 50px;
    }
    .look_house {
      background: #ffa71d;
    }
    .btns {
      color: #fff;
      width: 100%;
      height: 80px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 13px;
      margin-top: 30px;
      img {
        width: 36px;
        height: 36px;
      }
    }
    .describe {
      text-indent: 2em;
    }
    .cost {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 37px;
    }
  }
  .configuration {
    padding: 1px 30px 50px 30px;
    box-sizing: border-box;
    color: #666666;
    font-size: 24px;
    background: #f5f5f5;
    .icon_list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 40px;
      &_item {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        align-items: center;
        justify-content: center;
        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
