<template>
  <div
    class="swiper-container" ref="swiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <img :src="item.path"/>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  data() {
    return {
      bannerList: []
    };
  },
  methods: {
    onChangeSwiper() {
      const that = this;
      this.$nextTick(() => {
        new Swiper(".swiper-container", {
          loop: true, // 循环模式选项
          autoplay: false,
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            type: "fraction",
          },
          observer: true,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 500px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.swiper-pagination {
  width: 80px;
  padding: 4px 4px;
  font-size: 28px;
  color: #fff;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 640px;
  bottom: 24px;
  border-radius: 4px;
  z-index: 999;
}
</style>
