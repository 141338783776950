<template>
  <div class="container" v-if="showCode">
    <div class="mask">
      <div class="qrcode">
        <!-- <img v-lazy="path"> -->
        <img src="@/assets/image/ewm.png" />
        <span>手机扫一扫上方二维码，预约看房</span>
        <img
          src="../../../../assets/image/gb.png"
          class="close"
          @click="closeCode"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      path: "",
    };
  },
  watch: {
    showCode(n) {
      if (n) {
        this.stop();
      } else {
        this.move();
      }
    },
  },
  methods: {
    stop() {
      let mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    move() {
      let mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    closeCode() {
      this.$parent.showCode = false;
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  position: fixed;
  top: 0;
  z-index: 99999;
  .mask {
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    .qrcode {
      width: 500px;
      height: 500px;
      background: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 24px;
      color: #666;
      row-gap: 24px;
      position: relative;
      img {
        width: 324px;
        height: 324px;
        object-fit: cover;
      }
      .close {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
        position: absolute;
        left: 50%;
        bottom: -80px;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
